import React, { useState } from 'react';
import { Column, Row } from './';
import { Text } from './../text/text';

export const GridDemo = () => {
  const containerStyles = {
    marginBottom: '70px',
    paddingTop: '100px',
    width: '100%'
  };

  const contentStyle = {
    backgroundColor: 'red',
    height: '100px',
    marginBottom: '10px',
    width: '100%'
  };

  const titleStyle = {
    fontSize: '24px',
    marginLeft: '100px'
  };

  const textContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px'
  };

  const [useDevGrid, setUseGrid] = useState(false);

  const handleUseGrid = () => {
    setUseGrid(!useDevGrid);
  };

  return (
    <div>
      <div style={containerStyles}>
        <div style={titleStyle}>
          <button onClick={() => handleUseGrid()}>
            Click me to see the grid
          </button>
        </div>
        <h2 style={titleStyle}>Full Width - 25 Col</h2>
        <Row cols={25} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>Inset - 23 Col</h2>
        <Row cols={23} offset={1} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>2 Up - 12 Col</h2>
        <Row cols={12} gap={1} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>2 Up - 11 Col</h2>
        <Row cols={11} offset={1} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>3 Up - 7 Col</h2>
        <Row cols={7} offset={1} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>4 Up - 5 Col</h2>
        <Row cols={5} offset={1} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>6 Up - 3 Col</h2>
        <Row cols={3} offset={1} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>8 Up - 2 Col</h2>
        <Row cols={2} offset={1} useDevGrid={useDevGrid}>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>2/3 Split - 15/7 Col</h2>
        <Row cols={7} offset={1} useDevGrid={useDevGrid}>
          <Column cols={15}>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>1:2 Split - 11/5 Col</h2>
        <Row cols={5} offset={1} useDevGrid={useDevGrid}>
          <Column cols={11}>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>Responsive - 2 Col</h2>
        <Row cols={[25, 12]} useDevGrid={useDevGrid}>
          <Column gap={[0, 1]}>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>Responsive - 3 Col</h2>
        <Row cols={[25, 7]} useDevGrid={useDevGrid} offset={[0, 1]}>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
          <Column>
            <div style={contentStyle} />
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>Structured - 3 Col</h2>
        <Row
          cols={[23, 7]}
          offset={[1, 0]}
          useDevGrid={useDevGrid}
          bordered="always"
        >
          <Column offset={[1, 2]}>
            <div style={textContainer}>
              <Text variant="h2b">Here</Text>
            </div>
          </Column>
          <Column>
            <div style={textContainer}>
              <Text variant="h2b">Comes</Text>
            </div>
          </Column>
          <Column>
            <div style={textContainer}>
              <Text variant="h2b">The Sun</Text>
            </div>
          </Column>
        </Row>
      </div>
      <div style={containerStyles}>
        <h2 style={titleStyle}>24 Grid - 2 Col</h2>
        <Row
          cols={[22, 11]}
          useDevGrid={useDevGrid}
          bordered="always"
          gridLength={24}
          offset={1}
        >
          <Column>
            <div style={textContainer}>
              <Text variant="h2b">Being For The Benefit</Text>
            </div>
          </Column>
          <Column offset={[1, 0]}>
            <div style={textContainer}>
              <Text variant="h2b">Of Mr. Kite</Text>
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
};

import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Text, Dropdown } from '../..';
import { CaretIcon } from '../../molecules/icons';
import styles from './newsletter-dropdown.module.css';

export type NewsletterDropdownProps = {
  heading: string;
  items: { id: string; content: string }[];
};

export const NewsletterDropDown: FC<NewsletterDropdownProps> = ({
  heading,
  items
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      heading={
        <div
          className={clsx(styles.heading, 'px-sm', { [styles.open]: isOpen })}
        >
          <Text variant="p3a" className="text-left flex-grow">
            {heading}
          </Text>
          <CaretIcon
            direction={isOpen ? 'up' : 'down'}
            iconWidth="24"
            className={clsx(isOpen ? styles.iconOpen : styles.iconClosed)}
          />
        </div>
      }
      isOpen={isOpen}
      onToggleOpen={() => setIsOpen(!isOpen)}
      className="w-full"
    >
      <div className={styles.content}>
        {items.map((item, id) => (
          <Text
            key={`${item.id}-${id}`}
            variant="p3a"
            className={clsx('text-left mt-sm', styles.dropdownItem)}
          >
            {item.content}
          </Text>
        ))}
      </div>
    </Dropdown>
  );
};

import React, { useEffect, useState } from 'react';

import { Text } from '../../';
import { SearchIcon } from '../icons';
import { useIsMobileBreakpoint } from '../../../hooks/use-is-mobile-breakpoint';

import './search-box.scss';

type InputProps = React.HTMLProps<HTMLInputElement>;
type FormProps = React.HTMLProps<HTMLFormElement>;

export interface SearchBoxInputProps extends Omit<FormProps, 'onChange'> {
  inputProps?: React.HTMLProps<HTMLInputElement>;
  onChange?: InputProps['onChange'];
}
export const SearchBoxInput = React.forwardRef<
  HTMLFormElement,
  SearchBoxInputProps
>(({ onSubmit, onChange, inputProps, value, ...rest }, ref) => {
  const [underlineText, setUnderlineText] = useState(value);
  const isMobile = useIsMobileBreakpoint();

  useEffect(() => {
    const whiteSpace = '\u00A0';
    const badWhiteSpace = /\s/g;
    setUnderlineText(String(value).replace(badWhiteSpace, whiteSpace));
  }, [value]);

  return (
    <form className="search-box" onSubmit={onSubmit} {...rest} ref={ref}>
      <div className="flex-center search-box-wrapper">
        <Text
          {...(inputProps as any)}
          variant="p3a"
          htmlTag="input"
          className="search-box-input"
          value={value}
          onChange={onChange}
          ref={inputProps.ref}
        />
        {!isMobile && (
          <button className="px-tiny search-box-icon" type="submit">
            <SearchIcon iconWidth="16" />
          </button>
        )}
      </div>
      <div className="search-box-line">
        <Text variant="p3a" className="search-box-line-length">
          {underlineText}
        </Text>
      </div>
    </form>
  );
});

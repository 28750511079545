import React from 'react';

type IconGradientProps = {
  className?: string;
};

export const IconGradient: React.FC<IconGradientProps> = ({ className }) => (
  <svg
    width="1051"
    height="701"
    viewBox="0 0 1051 701"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M855.276 113.815C980.828 190.425 1076.57 283.697 1043.36 388.683C1010.15 493.669 848.126 610.796 660.271 664.929C472.416 719.062 257.492 711.011 131.539 637.766C5.58541 564.521 -32.7721 426.465 30.2178 295.53C93.2076 164.596 257.684 41.2089 416.848 9.46763C576.011 -22.2736 729.863 37.6309 855.276 113.815Z"
      fill="url(#paint0_linear_728_36267)"
      fillOpacity="0.35"
    />
    <defs>
      <linearGradient
        id="paint0_linear_728_36267"
        x1="140.344"
        y1="221.448"
        x2="689.458"
        y2="408.405"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F4EAE5" />
      </linearGradient>
    </defs>
  </svg>
);

import React, { useState } from 'react';
import { baseSanityNode } from '../../content/base-sanity-node';
import { ImageType } from '../../atoms/image/types';
import { Hero } from './hero';

export const HeroDemo = () => {
  const constainerStyle = { marginBottom: '50px', marginTop: '50px' };
  const [fullImage, setFullImage] = useState(false);
  const [reverseDesktop, setReverseDesktop] = useState(false);
  const [reverseMobile, setReverseMobile] = useState(false);
  const [imageType, setImageType] = useState(ImageType.SQUARE);
  const [keyline, setKeyline] = useState(false);
  const [textAlign, setTextAlign] = useState('center');

  const handleImageTypeChange = (event: any) => {
    setImageType(event.target.value);
  };

  const handleTextAlignChange = (event: any) => {
    setTextAlign(event.target.value);
  };

  return (
    <div>
      <div style={constainerStyle}>
        <Hero
          headline="Lorem ipsum dolor sit amet."
          desktopImage={{
            ...baseSanityNode,
            asset: {
              ...baseSanityNode,
              url:
                'https://www.experimenta.es/wp-content/uploads/2018/10/David-Bowie-red.jpg'
            },
            alt: 'His excelence',
            type: imageType
          }}
          eyebrow="Lorem Ipsum"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum consectetur sem nec quam feugiat porta. Nunc vitae neque nisl. Phasellus non felis pharetra."
          cta={{
            label: 'Lorem Ipsum',
            onClick: () => {
              console.log('Just for one day');
            },
            ctaTrackingLabel: 'test tracking'
          }}
          secondaryCta={{
            label: 'Lorem Ipsum',
            onClick: () => {
              console.log('Guitar');
            },
            ctaTrackingLabel: 'test tracking'
          }}
          orientation={[reverseMobile, reverseDesktop]}
          variant={fullImage ? 'fullImage' : 'home'}
          keyline={keyline}
          textAlign={textAlign}
        />
      </div>
      <div style={{ border: 'dashed 1px black' }}>
        <h2>Hero Demo Controls</h2>
        <div>
          <input
            type="checkbox"
            checked={fullImage}
            onClick={() => setFullImage(!fullImage)}
            id="fullimage"
          />
          <label htmlFor="fullimage">-Full Image</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={keyline}
            onClick={() => setKeyline(!keyline)}
            id="keyline"
          />
          <label htmlFor="keyline">-Show Keyline</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={reverseMobile}
            onClick={() => setReverseMobile(!reverseMobile)}
            id="reverseMobile"
          />
          <label htmlFor="reverseMobile">-Reverse Layout Mobile</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={reverseDesktop}
            onClick={() => setReverseDesktop(!reverseDesktop)}
            id="reverseDesktop"
          />
          <label htmlFor="reverseDesktop">-Reverse Layout Desktop</label>
        </div>
        <p>Image Type:</p>
        <div
          onChange={handleImageTypeChange}
          style={{ display: 'grid', gridTemplateColumns: '20px 1fr' }}
        >
          <input type="radio" value={ImageType.SQUARE} name="imageType" />{' '}
          SQUARE
          <input type="radio" value={ImageType.LARGE} name="imageType" /> LARGE
          <input
            type="radio"
            value={ImageType.RECTANGLE}
            name="imageType"
          />{' '}
          RECTANGLE
          <input
            type="radio"
            value={ImageType.RECTANGLE_XL}
            name="imageType"
          />{' '}
          RECTANGLE_XL
        </div>
        <p>Text Align:</p>
        <div
          onChange={handleTextAlignChange}
          style={{ display: 'grid', gridTemplateColumns: '20px 1fr' }}
        >
          <input type="radio" value="left" name="textAlign" /> Left
          <input type="radio" value="center" name="textAlign" /> Center
          <input type="radio" value="right" name="textAlign" /> Right
        </div>
      </div>
    </div>
  );
};

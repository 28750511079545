import React from 'react';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { OverlayContainer } from '@react-aria/overlays';

import { useIsMobileBreakpoint } from '../../../hooks/use-is-mobile-breakpoint';
import './search-box.scss';

export interface SearchBoxPopoverProps extends React.HTMLProps<HTMLDivElement> {
  isOpen: boolean;
  onClose(): void;
  children?: React.ReactNode;
}

export const SearchBoxPopover = React.forwardRef<
  HTMLDivElement,
  SearchBoxPopoverProps
>((props, ref) => {
  const { isOpen, onClose, children, ...rest } = props;
  const isMobile = useIsMobileBreakpoint();
  const OverlayContainerComp = isMobile ? React.Fragment : OverlayContainer;

  const { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: false
    },
    ref as React.RefObject<HTMLDivElement>
  );

  return (
    <OverlayContainerComp>
      {isOpen && (
        <div
          {...overlayProps}
          ref={ref}
          className="absolute search-box-popover"
          {...rest}
        >
          {children}
          <DismissButton onDismiss={onClose} />
        </div>
      )}
    </OverlayContainerComp>
  );
});

import React, { FC, useRef } from 'react';
import Carousel from 'react-multi-carousel';

import { useCarouselStep } from '../../../hooks/use-carousel-step';
import { DataListItem } from '../../molecules/collapsible-data-list/collapsible-data-list';
import {
  TextLockup,
  Text,
  CollapsibleDataList,
  Row,
  Column,
  CarouselStepIndicator
} from '../..';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import styles from './statistic-carousel.module.css';
import useWindowSize from '../../../hooks/use-window-size';
import { breakpoint } from '../../types/index';

export interface Stat extends DataListItem {
  chart: React.ReactElement;
}

export interface StatisticCarouselProps
  extends React.HTMLAttributes<HTMLElement> {
  eyebrow?: string;
  headline: string;
  items: Stat[];
}

const responsive = {
  device: {
    breakpoint: { max: 758, min: 0 },
    items: 1
  }
};

export const StatisticCarousel: FC<StatisticCarouselProps> = ({
  eyebrow,
  headline,
  items
}) => {
  const windowSize = useWindowSize();
  const isDevice = windowSize.width <= breakpoint;
  const { currentStep, goToStep } = useCarouselStep(
    items.length,
    isDevice ? 0 : 1
  );
  const carousel = useRef<Carousel | null>(null);
  const cols = [22, 11];
  const { chart } = items[currentStep];

  const handleAfterChange = () => {
    window.heap.track('Carousel Swipe', {
      carouselName: 'Statistic Carousel',
      currentStep
    });
    goToStep(carousel?.current?.state?.currentSlide);
  };

  return (
    <Row cols={cols} gap={1} gridLength={24} offset={1}>
      {!isDevice ? (
        <>
          <Column>
            <TextLockup alignment="left">
              {eyebrow && (
                <Text className={styles.eyebrow} variant="p3b">
                  {eyebrow}
                </Text>
              )}
              <Text variant="h2a">{headline}</Text>
              <CollapsibleDataList
                items={items as DataListItem[]}
                activeItem={currentStep}
                onItemClick={goToStep}
              />
            </TextLockup>
          </Column>
          <Column>{chart}</Column>
        </>
      ) : (
        <Column className="text-center">
          {eyebrow && (
            <Text className={styles.eyebrow} variant="p3b">
              {eyebrow}
            </Text>
          )}
          <Text className={styles.headline} variant="h2a">
            {headline}
          </Text>
          <ParsleyCarousel
            containerClass={styles.carouselContainer}
            responsive={responsive}
            leftArrowClass={styles.leftArrow}
            rightArrowClass={styles.rightArrow}
            ref={carousel}
            afterChange={handleAfterChange}
          >
            {items.map((i, index) => {
              const Slide = i.chart;
              return (
                <div key={`${i.key}-${index}`}>
                  {Slide}
                  <Text variant="h6a" className={styles.mobileSectionTitle}>
                    {i.title}
                  </Text>
                  <Text
                    variant="p2a"
                    className={styles.mobileSectionDescription}
                  >
                    {i.description}
                  </Text>
                </div>
              );
            })}
          </ParsleyCarousel>
          <CarouselStepIndicator
            className={styles.stepIndicator}
            currentStep={currentStep}
            totalSteps={items.length}
            primaryColor={'clover'}
            accentColor="dark-green"
            orientation="horizontal"
          />
        </Column>
      )}
    </Row>
  );
};

import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const PlayIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M27.4369 17.0842L10.9686 7.19202C10.127 6.68648 9.00782 7.23816 9.00782 8.15854L9.00781 27.943C9.00781 28.8634 10.127 29.415 10.9686 28.9095L27.4369 19.0173C28.1975 18.5604 28.1975 17.5411 27.4369 17.0842Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

import React, { FC } from 'react';
import clsx from 'clsx';
import './newsletter.scss';
import { Text, NewsletterSignUpForm } from '../..';
import {
  NewsletterDropDown,
  NewsletterDropdownProps
} from './newsletter-dropdown';

export type NewsletterProps = {
  eyebrow: string;
  headline: string;
  copy: string;
  dropdown: NewsletterDropdownProps;
  variant: 'full' | 'half';
  ctaTrackingLabel: string;
  formTrackingLabel: string;
};

export const Newsletter: FC<NewsletterProps> = ({
  eyebrow,
  headline,
  copy,
  dropdown,
  variant = 'full',
  ctaTrackingLabel,
  formTrackingLabel
}) => {
  return (
    <div
      className={clsx('newsletter text-center', {
        half: variant === 'half'
      })}
    >
      <Text variant="p3b" className="eyebrow">
        {eyebrow}
      </Text>
      <Text variant="h3a" className="headline">
        {headline}
      </Text>
      <Text variant="p2a" className="copy">
        {copy}
      </Text>
      <div className="newsletter-form flex-center flex-wrap">
        <div className="newsletter-form-item">
          <NewsletterDropDown
            heading={dropdown.heading}
            items={dropdown.items}
          />
        </div>
        <div className="newsletter-form-item newsletter-signup-form">
          <NewsletterSignUpForm
            ctaTrackingLabel={ctaTrackingLabel}
            formTrackingLabel={formTrackingLabel}
          />
        </div>
      </div>
    </div>
  );
};

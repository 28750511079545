import React, { FC } from 'react';
import clsx from 'clsx';

import { Row, Column, CarouselStepIndicator } from '../../';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import { ArrowIcon } from './../../molecules/icons';
import { useIsMobileBreakpoint } from '../../../hooks/use-is-mobile-breakpoint';
import './blog-hero.scss';
import { BlogItem } from './blog-item';
import { Blog } from './types';

export type BlogItemProps = Omit<Blog, 'id'>;

export interface BlogHeroProps {
  blogs: Blog[];
}

const ButtonGroup = ({ next, previous, ...rest }: any) => {
  const {
    carouselState: { currentSlide, totalItems }
  } = rest;
  const showPrevBtn = currentSlide !== 0;
  const showNextBtn = currentSlide !== totalItems - 1;
  const isMobile = useIsMobileBreakpoint();

  return (
    <Row
      gap={0}
      containerClassName={clsx('absolute bottom-md', {
        'bottom-lg': isMobile
      })}
    >
      <Column cols={[25, 13]}>
        <Row gap={0} offset={[0, 1]} cols={[25, 23]}>
          <Column
            className={clsx('flex-center align-center', {
              'justify-center': isMobile,
              'justify-between': !isMobile
            })}
          >
            <CarouselStepIndicator
              currentStep={currentSlide}
              totalSteps={totalItems}
              primaryColor="white"
              accentColor="white"
              className="carousel-step-indicator"
              primaryStepClassName="carousel-step-indicator-step-primary"
            />
            {!isMobile && (
              <div className="flex-center align-center">
                {showPrevBtn && (
                  <button onClick={previous} className="mr-xxs">
                    <ArrowIcon iconWidth="48" direction="left" circled />
                  </button>
                )}

                {showNextBtn && (
                  <button onClick={next}>
                    <ArrowIcon iconWidth="48" direction="right" circled />
                  </button>
                )}
              </div>
            )}
          </Column>
        </Row>
      </Column>
      <Column cols={[25, 10]} />
    </Row>
  );
};

export const BlogHero: FC<BlogHeroProps> = props => {
  const { blogs } = props;
  const responsive = {
    device: {
      breakpoint: { max: 100000, min: 0 },
      items: 1
    }
  };
  const isMobile = useIsMobileBreakpoint();

  return (
    <Row gap={0}>
      <Column className="blog-carousel" cols={[25, 23]} offset={[0, 1]}>
        <ParsleyCarousel
          responsive={responsive}
          arrows={isMobile}
          customButtonGroup={<ButtonGroup />}
          infinite={false}
          leftArrowClass="carousel-left-arrow"
          rightArrowClass="carousel-right-arrow"
        >
          {blogs.map(({ id, ...blog }, i) => (
            <BlogItem key={`${id}-${i}`} {...blog} />
          ))}
        </ParsleyCarousel>
      </Column>
    </Row>
  );
};

import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const HoverCircleIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
    </IconSvg>
  );
};

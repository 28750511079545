import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './blog-post-tags-footer.module.css';
import { Tag, Text } from '../..';

interface Itag {
  title: string;
  destination: string;
}

export type BlogPostTagsFooterProps = {
  tags: Itag[];
};

export const BlogPostTagsFooter: FC<BlogPostTagsFooterProps> = ({ tags }) => {
  return (
    <div className={styles.container}>
      <div className={clsx('flex align-center', styles.tagList)}>
        <Text variant="u1a">Tags</Text>
        {tags.map((tag, i) => (
          <Tag
            key={`${tag.title}-${i}`}
            variant="light-gray"
            className={styles.tag}
            destination={tag.destination}
          >
            <Text variant="u1a">{tag.title}</Text>
          </Tag>
        ))}
      </div>
    </div>
  );
};

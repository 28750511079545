import React, { FC, useState } from 'react';
import clsx from 'clsx';

import { Text } from '../..';
import { Row, Column } from '../../atoms/grid';
import { Button } from '../../atoms/button';
import { CheckIcon } from '../../molecules/icons';
import useDeviceDetect from '../../../hooks/use-is-device-detect';
import './comparison-table.scss';
import { TextLockupModule } from '../text-lockup-module/text-lockup-module';
import { SanityTextLockup } from 'graphql-types';

interface HeaderItem {
  id: string;
  name: string;
}

interface Header {
  cols: number[][];
  aligns: string[];
  items: HeaderItem[];
}

interface TableCell {
  id: string;
  value: string | null;
  type: string;
  bold?: boolean;
}

interface TableRow {
  id: string;
  items: TableCell[];
}

interface TableData {
  cols: number[][];
  aligns: string[];
  rows: TableRow[];
}

interface Table {
  header: Header;
  data: TableData;
}

export type ComparisonTableProps = {
  textLockup: SanityTextLockup;
  table: Table;
  expandTableTrackingLabel?: string;
};

export const ComparisonTable: FC<ComparisonTableProps> = ({
  textLockup,
  table,
  expandTableTrackingLabel
}) => {
  const { header, data } = table;
  const [collapsed, setCollapsed] = useState(data.rows.length > 6);
  const { isMobile } = useDeviceDetect();
  const [leftSm, left] = header.cols[0];
  const [widthSm, width] = header.cols[1];

  return (
    <div
      className={clsx('text-center comparison-table-container', {
        collapsed: collapsed
      })}
    >
      <TextLockupModule {...textLockup} />
      <div className="table-container pt-xl">
        <Row bordered="always" lastRow={false}>
          {header.items.map(({ id, name }, index) => (
            <Column
              key={`${id}-${index}`}
              cols={header.cols[index]}
              className="cell flex-center"
            >
              <Text variant="p2b" className={'text-' + header.aligns[index]}>
                {name}
              </Text>
            </Column>
          ))}
        </Row>
        {data.rows.map(({ id, items }, rowIndex) => (
          <Row
            key={`${id}-${rowIndex}`}
            gap={0}
            bordered="always"
            lastRow={rowIndex === data.rows.length - 1}
          >
            {items.map(({ id, value, type, bold }, colIndex) => (
              <Column
                key={`${id}-${colIndex}`}
                cols={data.cols[colIndex]}
                className="cell flex-center"
              >
                {type === 'icon' ? (
                  <div className="icon-container">
                    <CheckIcon color="white" strokeWidth={2} className="icon" />
                  </div>
                ) : (
                  <Text
                    variant={bold ? 'p3b' : 'p3a'}
                    className={clsx('w-full', 'text-' + data.aligns[colIndex])}
                  >
                    {value}
                  </Text>
                )}
              </Column>
            ))}
          </Row>
        ))}
        <div
          className="column-overlay"
          style={{
            left: `calc(${
              isMobile ? leftSm : left
            } / var(--grid-length) * 100%)`,
            width: ` calc(${
              isMobile ? widthSm : width
            } / var(--grid-length) * 100%)`
          }}
        />
        <div className="bottom-overlay" />
        {collapsed && (
          <Button
            className="show-full-table-button flex-center"
            variant="filled"
            color="sage"
            onClick={() => setCollapsed(false)}
            trackingLabel={expandTableTrackingLabel}
          >
            Show Full Comparison Table
          </Button>
        )}
      </div>
    </div>
  );
};

import React, { FC, useState } from 'react';
import { OverlayContainer, usePreventScroll } from '@react-aria/overlays';

import { Button, Row, Column } from '../../';
import { useIsMobileBreakpoint } from '../../../hooks/use-is-mobile-breakpoint';
import { CloseIcon, SearchIcon } from '../icons';

interface SearchBoxMobileLayoutProps {
  form: string;
  ctaTrackingLabel: string;
  children?: any;
}

interface MobileViewProps extends SearchBoxMobileLayoutProps {
  close: () => void;
  children?: any;
}

const MobileView: FC<MobileViewProps> = ({
  children,
  form,
  close,
  ctaTrackingLabel
}) => {
  usePreventScroll();
  return (
    <OverlayContainer>
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-ivory pt-sm pb-md search-box-overlay">
        <Row
          className="h-full flex flex-col"
          cols={23}
          offset={1}
          containerClassName="h-full"
          gap={0}
        >
          <Column className="flex w-full justify-end mb-xxs">
            <button onClick={close}>
              <CloseIcon color="darkGreen" variant="solid" />
            </button>
          </Column>
          <Column className="flex-1">{children}</Column>
          <Column className="flex w-full justify-center absolute bottom-0">
            <Button
              className="w-full"
              type="submit"
              form={form}
              trackingLabel={ctaTrackingLabel}
            >
              Search
            </Button>
          </Column>
        </Row>
      </div>
    </OverlayContainer>
  );
};

export const SearchBoxMobileLayout: FC<SearchBoxMobileLayoutProps> = props => {
  const isMobile = useIsMobileBreakpoint();
  const [isOpen, setOpen] = useState(false);
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  const { children, ctaTrackingLabel } = props;

  if (!isMobile) {
    return <>{children}</>;
  }

  if (!isOpen) {
    return (
      <Button
        variant="outlined"
        onClick={open}
        trackingLabel={ctaTrackingLabel}
      >
        <SearchIcon className="mr-xs" color="darkGreen" iconWidth={16} />
        Search
      </Button>
    );
  }

  return <MobileView {...props} close={close} />;
};

import React, { FC } from 'react';
import clsx from 'clsx';

import { Text, Row, Column, Tag, Button } from '../../';
import { Blog } from './types';
import './blog-hero.scss';

export type BlogItemProps = Omit<Blog, 'id'>;

export const BlogItem: FC<BlogItemProps> = props => {
  const { tag, headline, cta, image } = props;
  return (
    <Row
      containerClassName={clsx('blog-hero-container', 'flex align-center')}
      className={clsx('blog-hero', 'flex align-center w-full h-full')}
      gap={0}
      cols={25}
    >
      <Column className={clsx('blog-content', 'h-full')} cols={[25, 13]}>
        <Row
          gap={0}
          className={clsx('blog-content-row', 'h-full flex-center py-md')}
          containerClassName="h-full"
          cols={25}
          offset={[0, 1]}
        >
          <Column className="blog-content">
            <Tag variant={tag.variant} className="blog-content-tag">
              {tag.name}
            </Tag>
            <Text
              variant="h2a"
              className={clsx('blog-headline', 'mb-md text-center-sm')}
              color="white"
            >
              {headline}
            </Text>
            <Button
              variant="outlined"
              onClick={cta.callBack}
              color="white"
              trackingLabel={cta.ctaTrackingLabel}
            >
              {cta.text}
            </Button>
          </Column>
        </Row>
      </Column>
      <Column className="w-full h-full" cols={[25, 12]}>
        <img src={image.src} alt={image.alt} className="blog-image" />
      </Column>
    </Row>
  );
};

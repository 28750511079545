import React, { FC } from 'react';
import './utlity-nav.css';
import clsx from 'clsx';

import { Text } from '../../atoms/text/text';
import { Link } from '../../atoms/link/link';

import JSONData from '../../content/home/utility-nav-unsure-if-parsley-is-right.json';

export const UtilityNav: FC = () => {
  return (
    <div className={clsx('text-center', 'bg-dark-green', 'utility-nav')}>
      <Text variant="u1a" color="white" htmlTag="span" className={'mr-tiny'}>
        {JSONData.copy}
      </Text>
      <Link underline destination={JSONData.link.destination} color="white">
        {JSONData.link.copy}
      </Link>
    </div>
  );
};

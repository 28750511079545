import React, { FC } from 'react';
import clsx from 'clsx';
import { IconGradient } from './icon-gradient';
import { Row, Column } from '../../atoms/grid';
import { Image } from '../../atoms/image/image';
import { ImageType } from '../../atoms/image/types';
import './membership-hero.scss';
import useDeviceDetect from '../../../hooks/use-is-device-detect';
import { SanityTextLockup } from 'graphql-types';
import { TextLockupModule } from '../text-lockup-module/text-lockup-module';

export type MembershipHeroProps = {
  leftImage: any;
  rightImage: any;
  textLockup: SanityTextLockup;
  className?: string;
};

export const MembershipHero: FC<MembershipHeroProps> = ({
  className,
  textLockup,
  leftImage,
  rightImage
}) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div className="relative visual-hero-v2-main-container">
      {!isMobile && <IconGradient className="visual-hero-v2-icon-gradient" />}
      <Row
        gap={[0, 1]}
        className={clsx('visual-hero-v2-container-hero', className)}
        gridLength={24}
      >
        {isMobile && (
          <IconGradient className="visual-hero-v2-icon-gradient-mobile" />
        )}
        <Column cols={[24, 5]} className="visual-hero-v2-first-col">
          <div>
            <Image {...leftImage} type={leftImage?.type ?? ImageType.SQUARE} />
          </div>
        </Column>
        <Column cols={[24, 12]}>
          <TextLockupModule {...textLockup} />
        </Column>
        <Column cols={[0, 5]} className="visual-hero-v2-last-col">
          <Image {...rightImage} type={rightImage?.type ?? ImageType.SQUARE} />
        </Column>
      </Row>
    </div>
  );
};

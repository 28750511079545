import React, { FC } from 'react';
import { AriaListBoxOptions, useListBox, useOption } from '@react-aria/listbox';
import { ListState } from 'react-stately';
import { Node } from '@react-types/shared';
import clsx from 'clsx';

import { Text } from '../../';

interface SearchBoxListProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

const Option: FC<OptionProps> = ({ item, state }) => {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isFocused } = useOption(
    {
      key: item.key
    },
    state,
    ref
  );

  return (
    <Text
      {...(optionProps as any)}
      variant="p3a"
      htmlTag="li"
      ref={ref}
      className={clsx('search-box-list-item  w-full', {
        'search-box-list-item-focus': isFocused
      })}
    >
      {item.rendered}
    </Text>
  );
};

export const SearchBoxList = React.forwardRef<
  HTMLUListElement,
  SearchBoxListProps
>((props, listBoxRef) => {
  const { state } = props;
  const { listBoxProps } = useListBox(
    props,
    state,
    listBoxRef as React.RefObject<HTMLUListElement>
  );

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      className="search-box-list w-full pb-xxs"
    >
      {[...state.collection].map((item, i) => (
        <Option key={`${item.key}-${i}`} item={item} state={state} />
      ))}
    </ul>
  );
});

import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const CircleIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="-1 -1 26 26" fill="currentColor" {...props}>
      <circle cx="12" cy="12" r="11.5" stroke="currentColor" />
    </IconSvg>
  );
};

import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './review.module.css';
import { Text, Row, Column, Rating } from '../..';

interface ItemReviewProps {
  patientName: string;
  quote: string;
  rating: number;
  id: number;
}

interface ReviewProps {
  headline: string;
  reviews: ItemReviewProps[];
}

const Review: FC<ReviewProps> = ({ headline, reviews }) => {
  return (
    <div className={clsx(styles.container, 'mb-lg')}>
      <Text variant="h4a" color="dark-green" className="text-center mb-lg">
        {headline}
      </Text>
      <Row cols={[23, 7]} offset={2}>
        {reviews.map(({ patientName, quote, rating, id }, i) => (
          <Column key={`${id}-${i}`}>
            <div className="flex flex-col h-full">
              <Rating iconSize={15} rating={rating} className="mb-xs" />
              <div className="flex flex-1 mb-md align-center">
                <Text variant="p3a" color="utility-black">
                  {quote}
                </Text>
              </div>
              <Text variant="u1b" color="utility-black">
                {patientName.toUpperCase()}
              </Text>
            </div>
          </Column>
        ))}
      </Row>
    </div>
  );
};

export { Review };

import React, { FC, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import './blog-navigation.scss';
import { Link } from '../../atoms/link';
import { useIsHover } from '../../../hooks/use-is-hover';

interface Route {
  name: string;
  destination: string;
}

export type BlogNavigationProps = {
  routes: Route[];
};

type NavItemProps = {
  route: Route;
};

const NavItem: FC<NavItemProps> = ({ route }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isHover } = useIsHover({ element: ref });
  const { pathname: currentPath } = useLocation();

  const activeLinkClassName =
    'underline underspace underline-clover underline-offset-xtiny';

  const hoverInactiveLinkClassName =
    'underline underspace underline-light-clover underline-offset-xtiny';

  return (
    <div className="relative" ref={ref}>
      <Link
        variant="p3a"
        aria-label={`Navigate to ${route.name}`}
        destination={route.destination}
        activeClassName={activeLinkClassName}
        className={clsx('nav-item', {
          [hoverInactiveLinkClassName]:
            currentPath !== route.destination && isHover
        })}
        draggable={false}
      >
        {route.name}
      </Link>
    </div>
  );
};

export const BlogNavigation: FC<BlogNavigationProps> = ({ routes }) => {
  const [swipedOffset, setSwipedOffset] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    const { scrollWidth, offsetWidth } = ref.current;

    setSwipedOffset(offset =>
      Math.min(
        Math.max(offset + e.movementX, offsetWidth - (scrollWidth - offset)),
        0
      )
    );
  };

  return (
    <div className="blog-navigation" onMouseDown={handleMouseDown} ref={ref}>
      <nav className="flex align-center" style={{ marginLeft: swipedOffset }}>
        {routes.map((route, i) => (
          <NavItem key={`${route.name}-${i}`} route={route} />
        ))}
      </nav>
    </div>
  );
};

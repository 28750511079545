import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const PauseIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      <circle cx="18.0117" cy="18" r="13.25" stroke="currentColor" />
      <line
        x1="15.1318"
        y1="14.0834"
        x2="15.1318"
        y2="22.9537"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="20.3174"
        y1="14.0834"
        x2="20.3174"
        y2="22.9537"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </IconSvg>
  );
};

import React, { FC } from 'react';
import { Row, Column, Text } from '../..';

export type BlogPostIntroProps = {
  intro: string;
};

export const BlogPostIntro: FC<BlogPostIntroProps> = ({ intro }) => {
  return (
    <Row cols={[21, 13]} offset={[2, 6]}>
      <Column>
        <Text variant="h7a">{intro}</Text>
      </Column>
    </Row>
  );
};

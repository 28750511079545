import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const YoutubeIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M29.7609 13.6422C29.7609 13.6422 29.5266 11.9875 28.8047 11.2609C27.8906 10.3047 26.8688 10.3 26.4 10.2437C23.0438 10 18.0047 10 18.0047 10H17.9953C17.9953 10 12.9563 10 9.6 10.2437C9.13125 10.3 8.10938 10.3047 7.19531 11.2609C6.47344 11.9875 6.24375 13.6422 6.24375 13.6422C6.24375 13.6422 6 15.5875 6 17.5281V19.3469C6 21.2875 6.23906 23.2328 6.23906 23.2328C6.23906 23.2328 6.47344 24.8875 7.19062 25.6141C8.10469 26.5703 9.30469 26.5375 9.83906 26.6406C11.7609 26.8234 18 26.8797 18 26.8797C18 26.8797 23.0438 26.8703 26.4 26.6313C26.8688 26.575 27.8906 26.5703 28.8047 25.6141C29.5266 24.8875 29.7609 23.2328 29.7609 23.2328C29.7609 23.2328 30 21.2922 30 19.3469V17.5281C30 15.5875 29.7609 13.6422 29.7609 13.6422ZM15.5203 21.5547V14.8094L22.0031 18.1937L15.5203 21.5547Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

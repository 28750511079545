import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import { Row, Column } from '../../atoms/grid';
import { Text, BulletList } from '../..';
import Carousel from 'react-multi-carousel';
import { useCarouselStep } from '../../../hooks/use-carousel-step';
import useWindowSize from '../../../hooks/use-window-size';
import { breakpoint } from '../../types/index';

import './timeline-carousel.scss';

export type TimelineCarouselProps = {
  heading?: ReactNode;
  description?: string;
  slides: TimelineCarouselSlideProps[];
};

interface TimelineCarouselSlideProps {
  title?: string;
  header?: string;
  benefits?: Array<string>;
  id?: number;
  isCarouselMoving?: boolean;
}

export type TimelineCarouselProgressProps = {
  selectedSlide: number;
  numOfSlides: number;
};

const TimelineCarouselSlide = React.forwardRef<
  HTMLDivElement,
  TimelineCarouselSlideProps
>(({ id, title, header, benefits }, ref) => {
  return (
    <div className="timeline-carousel-slide" key={id} ref={ref}>
      <div className="timeline-carousel-slide-header">
        <Text variant="h6b">{header}</Text>
        <Text variant="p1a">{title}</Text>
      </div>
      <div className="timeline-carousel-text-card">
        <BulletList items={benefits} className="timeline-carousel-bullets" />
      </div>
    </div>
  );
});

const TimelineCarouselProgress: FC<TimelineCarouselProgressProps> = ({
  selectedSlide,
  numOfSlides
}) => {
  return (
    <div className="timeline-carousel-progress">
      {[...Array(numOfSlides)].map((_, i) => (
        <div
          key={'timeline-slide_' + i}
          className={`progress-indicator ${
            i === selectedSlide - 1 ? 'selected' : ''
          }`}
        />
      ))}
    </div>
  );
};

export const TimelineCarousel: FC<TimelineCarouselProps> = ({
  heading,
  description,
  slides
}) => {
  const [isMoving, setIsMoving] = useState(false);
  const [selected, setSelected] = useState(0);
  const carousel = useRef<Carousel | null>(null);
  const { currentStep, goToStep } = useCarouselStep(slides.length, 2);

  const windowSize = useWindowSize();
  const isDevice = windowSize.width <= breakpoint;

  useEffect(() => {
    // On component mount, make sure that the carousel is never positioned on the empty slide.
    if (carousel?.current?.state?.currentSlide === 0) {
      carousel.current.goToSlide(1);
    }
  }, [goToStep]);

  // First and last slides are blank
  const hideLeftArrow = currentStep === 1; // Can't allow users to slide the carousel back to '0' slide
  const hideRightArrow = currentStep === 6; // Can't allow users to slide the carousel forward to last slide

  const responsive = {
    desktop: {
      breakpoint: { min: 1025, max: 100000 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    device: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    }
  };

  return (
    <Column>
      <Row cols={1} className="timeline-carousel-header">
        {heading && (
          <Text
            variant="h2a"
            color="dark-green"
            className="text-lockup-headline mb-md"
          >
            {heading}
          </Text>
        )}
        {description && (
          <Text
            variant="p2a"
            color="dark-green"
            className="text-lockup-description"
          >
            {description}
          </Text>
        )}
      </Row>
      <ParsleyCarousel
        responsive={responsive}
        containerClass="timeline-carousel-container"
        leftArrowClass={
          hideLeftArrow ? 'hide-left' : 'timeline-carousel-left-button'
        }
        rightArrowClass={
          hideRightArrow ? 'hide-right' : 'timeline-carousel-right-button'
        }
        beforeChange={() => setIsMoving(true)}
        afterChange={(_, { currentSlide }) => {
          window.heap.track('Carousel Swipe', {
            carouselName: 'Timeline Carousel',
            currentStep: currentSlide
          });
          setIsMoving(false);
          goToStep(currentSlide);
          setSelected(currentSlide);
        }}
        arrowSize={isDevice ? '36' : '48'}
        ref={carousel}
        partialVisible={isDevice}
        additionalTransfrom={isDevice ? 20 : 200}
      >
        {slides.map((s: TimelineCarouselSlideProps) => (
          <TimelineCarouselSlide
            key={s.id}
            benefits={s.benefits}
            title={s.title}
            header={s.header}
            isCarouselMoving={isMoving}
          />
        ))}
      </ParsleyCarousel>
      {isDevice && (
        <TimelineCarouselProgress
          selectedSlide={selected}
          numOfSlides={slides.length - 2} // Minus the two blank outside slides
        />
      )}
    </Column>
  );
};

import React, { FC, useRef } from 'react';
import { useComboBoxState } from 'react-stately';
import { useFilter } from '@react-aria/i18n';
import { useOverlayPosition } from '@react-aria/overlays';
import { useComboBox } from '@react-aria/combobox';

import { ComboBoxProps } from '@react-types/combobox';

import { SearchBoxPopover } from './search-box-popover';
import { SearchBoxInput } from './search-box-input';
import { SearchBoxList } from './search-box-list';
import { SearchBoxMobileLayout } from './search-box-mobile-layout';
import { useIsMobileBreakpoint } from '../../../hooks/use-is-mobile-breakpoint';
import './search-box.scss';

export interface ItemType {
  id: string | number;
  text: string;
}

export interface SearchComboBoxProps extends ComboBoxProps<ItemType> {
  onSubmit?: React.HTMLProps<HTMLFormElement>['onSubmit'];
  id: string;
  ctaTrackingLabel: string;
}

export const SearchComboBox: FC<SearchComboBoxProps> = props => {
  const { onSubmit, id, ctaTrackingLabel } = props;
  const { contains } = useFilter({ sensitivity: 'base' });
  const isMobile = useIsMobileBreakpoint();
  const state = useComboBoxState({
    ...props,
    defaultFilter: contains,
    allowsCustomValue: true,
    shouldCloseOnBlur: !isMobile
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const listBoxRef = useRef(null);
  const popoverRef = useRef(null);
  const { inputProps, listBoxProps } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef
    },
    state
  );
  const { isOpen, close } = state;
  const {
    overlayProps: { style }
  } = useOverlayPosition({
    targetRef: inputRef,
    overlayRef: popoverRef,
    placement: 'bottom left',
    offset: 14,
    isOpen
  });
  const listWidth = formRef.current?.getBoundingClientRect().width;
  const popoverStyles = isMobile
    ? { width: listWidth }
    : { ...style, width: listWidth };

  return (
    <SearchBoxMobileLayout form={id} ctaTrackingLabel={ctaTrackingLabel}>
      <SearchBoxInput
        inputProps={{ ...inputProps, ref: inputRef }}
        ref={formRef}
        onChange={inputProps.onChange}
        value={inputProps.value}
        onSubmit={onSubmit}
        id={id}
      />
      <SearchBoxPopover
        isOpen={isOpen}
        onClose={close}
        ref={popoverRef}
        style={popoverStyles}
      >
        <SearchBoxList {...listBoxProps} ref={listBoxRef} state={state} />
      </SearchBoxPopover>
    </SearchBoxMobileLayout>
  );
};

import React, { FC } from 'react';
import { Item } from 'react-stately';

import {
  SearchComboBox,
  SearchComboBoxProps,
  ItemType
} from './search-combo-box';

export interface SearchBoxProps extends Omit<SearchComboBoxProps, 'children'> {
  items: ItemType[];
  ctaTrackingLabel: string;
}

export const SearchBox: FC<SearchBoxProps> = ({
  items,
  onSubmit,
  ctaTrackingLabel,
  ...props
}) => {
  const handleSubmit: SearchComboBoxProps['onSubmit'] = e => {
    e.preventDefault();
    onSubmit(e);
  };
  return (
    <SearchComboBox
      defaultItems={items}
      onSubmit={handleSubmit}
      ctaTrackingLabel={ctaTrackingLabel}
      {...props}
    >
      {({ text }) => <Item>{text}</Item>}
    </SearchComboBox>
  );
};
